import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Page from '../templates/Page';
import { SEO, TopNavigation, Section } from '../components';
import TagsFilter from '../compositions/BlogListing/TagsFilter';
import BlogItem from '../compositions/BlogListing/BlogItem';
import { Wrapper, BlogList } from '../compositions/BlogListing/BlogListing.style';

/**
 * Blog page
 */
const Blog = props => {
  const {
    data: { allMdx },
  } = props;
  const blogList = allMdx.edges;
  const tags = allMdx.group;
  const [posts, setPosts] = useState(blogList);

  const handleFilter = (tag = '') => setPosts(blogList.filter(item => item.node.frontmatter.tags.includes(tag)));

  return (
    <Page>
      <SEO title="Writings | Danijel Grabež" pagePath={props.path} />
      <TopNavigation title="Writings" />
      <Wrapper>
        <Section
          sidebar={<TagsFilter blogList={blogList} tags={tags} setPosts={setPosts} handleFilter={handleFilter} />}
        >
          <BlogList>
            {posts.map(item => (
              <BlogItem
                frontmatter={item.node.frontmatter}
                slug={item.node.fields.slug}
                key={item.node.frontmatter.excerpt}
              />
            ))}
          </BlogList>
        </Section>
      </Wrapper>
    </Page>
  );
};

Blog.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
              inlineSubtitle: PropTypes.string,
              excerpt: PropTypes.string,
              tags: PropTypes.array,
              date: PropTypes.string,
              image: PropTypes.any,
              postKind: PropTypes.oneOf(['default', 'quote']),
              blogThemeConfig: PropTypes.shape({
                color: PropTypes.oneOf(['text', 'light', 'dark']),
                background: PropTypes.string,
              }),
            }),
          }),
        })
      ),
      group: PropTypes.arrayOf(PropTypes.shape({ fieldValue: PropTypes.string })),
    }),
  }).isRequired,
};

export default Blog;

export const pageQuery = graphql`
  query AllPosts {
    allMdx(limit: 1000, sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      group(field: frontmatter___tags) {
        fieldValue
      }
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            inlineSubtitle
            excerpt
            tags
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            postKind
            blogThemeConfig {
              color
              background
            }
          }
        }
      }
    }
  }
`;
