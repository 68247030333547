import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextAccent } from '../../components';
import { Aside, AsideContent, List, Item, FilterOptionButton, FilterButton } from './TagsFilter.style';

/**
 * Blog: Tags filter
 */
const TagsFilter = ({ blogList, tags, setPosts, handleFilter }) => {
  const [activeFilter, setActiveFilter] = useState('All');
  const [isVisible, toggleFilter] = useState(false);

  const handleFilterChange = value => {
    toggleFilter(false);
    handleFilter(value);
    setActiveFilter(value);
  };

  return (
    <Aside>
      <AsideContent>
        <TextAccent colorKind="neutral" onClick={() => toggleFilter(!isVisible)}>
          Filter: <FilterButton isFilterVisible={isVisible}>#{activeFilter}</FilterButton>
        </TextAccent>
        <List isVisible={isVisible}>
          <Item>
            <FilterOptionButton
              isActive={activeFilter === 'All'}
              onClick={() => {
                setPosts(blogList);
                setActiveFilter('All');
                toggleFilter(false);
              }}
            >
              All
            </FilterOptionButton>
          </Item>
          {tags.map(item => (
            <Item key={item.fieldValue}>
              <FilterOptionButton
                isActive={activeFilter === item.fieldValue}
                onClick={() => {
                  handleFilterChange(item.fieldValue);
                }}
              >
                {item.fieldValue}
              </FilterOptionButton>
            </Item>
          ))}
        </List>
      </AsideContent>
    </Aside>
  );
};

TagsFilter.propTypes = {
  blogList: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        fields: PropTypes.shape({
          slug: PropTypes.string,
        }),
        frontmatter: PropTypes.shape({
          title: PropTypes.string,
          excerpt: PropTypes.string,
          tags: PropTypes.array,
          date: PropTypes.string,
          postKind: PropTypes.oneOf(['default', 'quote']),
          blogThemeConfig: PropTypes.shape({
            color: PropTypes.oneOf(['text', 'light', 'dark']),
            background: PropTypes.string,
          }),
        }),
      }),
    })
  ).isRequired,
  tags: PropTypes.array.isRequired,
  setPosts: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default TagsFilter;
