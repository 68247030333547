import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';
import theme from '../utils/theme';
import { scaffoldingStyles } from '../utils/scaffolding';
import { Container, Footer } from '../components';

/**
 * Page template
 * Info: Used on non-blog related pages
 */
const Page = ({ children, preContent }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Global styles={scaffoldingStyles} />
        {preContent}
        <Container>
          <main>{children}</main>
          <Footer />
        </Container>
      </>
    </ThemeProvider>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  preContent: PropTypes.node,
};

Page.defaultProps = {
  preContent: null,
};

export default Page;
