import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { getLinkColorStyles } from '../../components/Link/Link.style';

const asideStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin-bottom: 40px;
  ${breakpoint.fromTablet} {
    flex-direction: column;
    margin: 0;
  }
`;

const asideContentStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  ${breakpoint.fromTablet} {
    flex-direction: column;
    margin: 0;
    position: sticky;
    top: 50px;
    z-index: 5;
  }
`;

const listStyles = ({ isVisible }) => css`
  display: ${isVisible ? 'block' : 'none'};
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
  opacity: ${isVisible ? 1 : 0};
  transform: scale(${isVisible ? 1 : 0});
  transition: 0.15s opacity ease-in-out;
  position: relative;
  top: -30px;
  left: 63px;
`;

const itemStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  &:hover {
    text-decoration: underline;
  }
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  ${breakpoint.phones} {
    margin: 0;
    &:not(:last-of-type) {
      margin-right: 30px;
    }
  }
`;

const filterOptionButtonStyles = ({ isActive }) => css`
  text-align: left;
  font-weight: ${isActive ? 700 : 400};
  outline: none;
  padding: 0;
  border: 0;
  background: 0;
  cursor: pointer;
  transition: 0.15s all ease-in-out;
  ${getLinkColorStyles('text')};
`;

const filterButtonStyles = ({ isFilterVisible }) => css`
  text-align: left;
  font-weight: 700;
  text-decoration: underline;
  outline: none;
  padding: 0;
  border: 0;
  background: 0;
  cursor: pointer;
  transition: 0.15s all ease-in-out;
  opacity: ${isFilterVisible ? 0 : 1};
  ${getLinkColorStyles('text')};
`;

/**
 * Component style declarations
 */
export const Aside = styled('aside')(asideStyles);
export const AsideContent = styled('nav')(asideContentStyles);
export const List = styled('ul')(listStyles);
export const Item = styled('li')(itemStyles);
export const FilterOptionButton = styled('button')(filterOptionButtonStyles);
export const FilterButton = styled('button')(filterButtonStyles);
